import { ArticleTeaserDefault, ArticleTeaserDefaultProps } from '@/components/ArticleTeaser/Default';
import c from '@/styles/Search';
import { StandaloneComponent } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface SearchItemProps extends ArticleTeaserDefaultProps {}

export const SearchItemComponent: StandaloneComponent<SearchItemProps> = ({ options, image, ...props }) => {
  const { $caption, $description, $group, $headline, colors, variant, size, className, ...baseProps } = options ?? {};

  const {
    colors: captionColors,
    variant: captionVariant,
    size: captionSize,
    className: captionClassName,
    ...captionProps
  } = $caption ?? {};

  const {
    colors: descriptionColors,
    variant: descriptionVariant,
    size: descriptionSize,
    className: descriptionClassName,
    ...descriptionProps
  } = $description ?? {};

  const {
    colors: groupColors,
    variant: groupVariant,
    size: groupSize,
    className: groupClassName,
    ...groupProps
  } = $group ?? {};

  const {
    colors: headlineColors,
    variant: headlineVariant,
    size: headlineSize,
    className: headlineClassName,
    ...headlineProps
  } = $headline ?? {};

  const getPropStyles = getPropStylesFactory(c);

  const captionColorsClassName = getPropStyles('item_caption_colors', captionColors, 'default');
  const captionVariantClassName = getPropStyles('item_caption_variant', captionVariant, 'horizontal');
  const captionSizeClassName = getPropStyles('item_caption_size', captionSize, 'horizontal');
  const captionComponentClassName = cn(
    captionColorsClassName,
    captionVariantClassName,
    captionSizeClassName,
    captionClassName,
  );

  const descriptionColorsClassName = getPropStyles('item_description_colors', descriptionColors, 'default');
  const descriptionVariantClassName = getPropStyles('item_description_variant', descriptionVariant, 'horizontal');
  const descriptionSizeClassName = getPropStyles('item_description_size', descriptionSize, 'horizontal');
  const descriptionComponentClassName = cn(
    descriptionColorsClassName,
    descriptionVariantClassName,
    descriptionSizeClassName,
    descriptionClassName,
  );

  const groupColorsClassName = getPropStyles('item_group_colors', groupColors, 'default');
  const groupVariantClassName = getPropStyles('item_group_variant', groupVariant, 'horizontal');
  const groupSizeClassName = getPropStyles('item_group_size', groupSize, 'horizontal');
  const groupComponentClassName = cn(groupColorsClassName, groupVariantClassName, groupSizeClassName, groupClassName);

  const headlineColorsClassName = getPropStyles('item_headline_colors', headlineColors, 'default');
  const headlineVariantClassName = getPropStyles('item_headline_variant', headlineVariant, 'horizontal');
  const headlineSizeClassName = getPropStyles('item_headline_size', headlineSize, 'horizontal');
  const headlineComponentClassName = cn(
    headlineColorsClassName,
    headlineVariantClassName,
    headlineSizeClassName,
    headlineClassName,
  );

  const imageColorsClassName = getPropStyles('item_image_colors', 'default');
  const imageVariantClassName = getPropStyles('item_image_variant', 'horizontal');
  const imageSizeClassName = getPropStyles('item_image_size', 'horizontal');
  const imageComponentClassName = cn(imageColorsClassName, imageVariantClassName, imageSizeClassName);

  const colorsClassName = getPropStyles('item_colors', colors, 'default');
  const variantClassName = getPropStyles('item_variant', variant, 'horizontal');
  const sizeClassName = getPropStyles('item_size', size, 'horizontal');
  const componentClassName = cn(colorsClassName, variantClassName, sizeClassName, className);

  return (
    <ArticleTeaserDefault
      image={{
        ...image,
        options: {
          className: imageComponentClassName,
        },
      }}
      options={{
        $caption: {
          colors: captionColors,
          variant: captionVariant ?? 'horizontal',
          size: captionSize ?? 'horizontal',
          className: captionComponentClassName,
          ...captionProps,
        },
        $description: {
          colors: descriptionColors,
          variant: descriptionVariant ?? 'horizontal',
          size: descriptionSize ?? 'horizontal',
          className: descriptionComponentClassName,
          ...descriptionProps,
        },
        $group: {
          colors: groupColors,
          variant: groupVariant ?? 'horizontal',
          size: groupSize ?? 'horizontal',
          className: groupComponentClassName,
          ...groupProps,
        },
        $headline: {
          colors: headlineColors,
          variant: headlineVariant ?? 'horizontal',
          size: headlineSize ?? 'horizontal',
          className: headlineComponentClassName,
          ...headlineProps,
        },
        colors,
        variant: variant ?? 'horizontal',
        size: size ?? 'horizontal',
        className: componentClassName,
        ...baseProps,
      }}
      {...props}
    />
  );
};
